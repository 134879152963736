import React, { useState } from "react"
const Toggle = ()=> {
  const [toggle, setToggle] = useState(true);

  function toggleDarkmode() {
    if(toggle){
      var element = document.getElementById("body");
      element.classList.add("dark");
      setToggle(false);
    }else{
      var element = document.getElementById("body");
      element.classList.remove("dark");
      setToggle(true);
    }   
  }
  return(
    <div className="colorchangediv" style={{marginTop: `1.2em`}}>		
      <input type="checkbox" id="toggle"/>
        <label htmlFor="toggle" onClick={() => toggleDarkmode()}></label>
    </div>
  )
}

export default Toggle
